.footerEditor {
  width: 100%;
  height: 96px;
  gap: 5px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding: 0 40px;

  .itemMenuEditor {
    border-radius: 8px;
    flex: 1;
    border: 1px dashed #5277f0;
    padding: 20px 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .control {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .name {
        color: #818181;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
      }
    }
  }
}

.content {
  padding: 15px 25px 5px;
  width: 100%;
  margin: 0 auto;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  display: none;
  padding-top: 35px;
  max-height: 200px;

  .iconClose {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.displayBlock {
  display: block;
}
