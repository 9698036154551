@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';
@import './reset.scss';
@import './library.scss';
@import './antdCustom.scss';
@import './variables';
* {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: $black;
}
#root {
  height: 100%;
}
.page-content {
  padding: 16px 16px 20px 16px;
  width: 100%;
  &-title {
    color: $main;
    font-weight: 700;
    font-size: 24px;
    padding: 8px 0px;
  }
}

.page-content-detail {
  padding: 24px 32px;
  &-title {
    font-weight: 700;
    font-size: 18px;
    color: $black;
    padding: 0px;
    padding-bottom: 16px;
    border-bottom: 1px solid #bebebe;
  }
}

.input-search {
  padding: 6px 16px;
  font-size: 14px;
  border: 1px solid $secondary;
  border-radius: 8px;
  background: $white;
  max-width: 423px;
  min-height: 40px;
  max-height: 40px;
  .ant-input {
    font-size: 16px;
  }
}

.date-picker-custom {
  min-width: 110px;
  min-height: 40px;
}

.button-add {
  display: flex;
  background: $main;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  color: $white;
  min-height: 48px;
  justify-content: center;
  border-color: $main;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  &-icon {
    margin-right: 4px;
  }
}

.dividing-line {
  width: 100%;
  height: 4px;
  background: linear-gradient(
    180deg,
    rgba(9, 30, 66, 0.13) 0%,
    rgba(9, 30, 66, 0.13) 25%,
    rgba(9, 30, 66, 0.08) 25.01%,
    rgba(9, 30, 66, 0) 100%
  );
}

.page-note {
  text-align: center;
  &-text {
    font-size: 24px;
    color: #091e42;
  }
}

.invalid-feedback {
  color: $danger;
  margin-top: 4px;
}
.civil-content-page {
  background: #f5f5f5;
  min-height: calc(100vh - $height-header);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.civil-content-page.civil-content-auth {
  align-items: flex-start;
  padding: 0;
}

.border-bottom-title {
  border-bottom: 1px solid $secondary;
}

.border-bottom-lighter {
  border-bottom: 1px solid #eaeaea;
}

.page-content-table {
  background: $white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.pagination-info {
  color: $primary;
  font-weight: 700;
  font-size: 14px;
}

.pagination-options.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid $secondary;
  border-radius: 8px;
  color: #272122;
  font-size: 16px;
}

.main-block {
  background: $white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.height-content-modal-preview {
  height: calc(100vh - 270px);
}

.x-spreadsheet-sheet {
  width: 100% !important;
}

.x-spreadsheet-menu .x-spreadsheet-icon {
  display: none;
}

.x-spreadsheet-menu .x-spreadsheet-dropdown .x-spreadsheet-icon {
  display: block;
}
.close-modal {
  position: absolute;
  right: 8px;
  top: 8px;
}
.text-blue {
  color: $main;
}

.page-only-header-title {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background: #f5f5f5;
  border-bottom: 1px solid $secondary;
  width: 100%;
  position: fixed;
  z-index: 99;
  .page-note-text {
    color: $main;
    font-weight: 700;
    font-size: 24px;
  }
  .page-detail-note {
    color: $secondary;
    font-weight: 700;
    font-size: 24px;
  }
  .text-blue {
    color: $main;
  }
}

.inprogress-color {
  background: #fff0ca !important;
  color: #27ae60 !important;
}

.approved-color {
  background: #d4fad4 !important;
  color: #27ae60 !important;
}

.reject-color {
  background: #fee9de !important;
  color: #ea9617 !important;
}

.expired-color {
  background: $secondary !important;
  color: #272122 !important;
}

.input-search-staff {
  max-width: 360px !important;
  width: 360px !important;
}

.license-chart {
  background: $white;
  border-radius: 8px;
  padding: 30px 13px;
  margin-bottom: 30px;
}

.time-line-license {
  position: relative;
  .main-line {
    background: #e9ebee;
    width: calc(100% - 30px);
    height: 8px;
    position: absolute;
    top: 28px;
  }
  .time-line-points {
    display: flex;
    justify-content: space-between;
  }

  .time-line-point {
    .icon {
      width: 3px;
      height: 22.067px;
      background: #e9ebee;
    }
  }
}

.profile_user {
  width: 687px;
  padding: 20px 16px 32px;
  background: $white;
  border-radius: 8px;
  margin: 0 auto;
  &-title {
    color: $main;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
    border-bottom: 1px solid #eaeaea;
  }
  &-avatar {
    width: 150px;
    position: relative;
    .avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
    }
    .icon-edit {
      cursor: pointer;
      position: absolute;
      right: 14px;
      top: 115px;
    }
  }
  &-info {
    width: calc(100% - 150px - 24px);
    margin-left: 24px;
    .input-form {
      background: #eaeaea;
      border: 1px solid $secondary;
      min-height: 48px;
    }
  }
}

.not-found-page {
  background: #f5f5f5;
  min-height: 100vh;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: $blue;
}

.ant-input {
  padding: 8px 16px;
  color: $primary;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Noto Sans JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  &::placeholder {
    overflow: hidden;
    color: #818181 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Noto Sans JP;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  &::-ms-input-placeholder {
    overflow: hidden;
    color: #818181;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.number-input {
  &::placeholder {
    overflow: hidden;
    color: #818181 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Noto Sans JP;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.ant-form-item-explain-error {
  color: $danger;
  font-family: Noto Sans JP;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  letter-spacing: 0.5px;
  margin-top: 4px;
}

.ant-form-item-label > label {
  color: $primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  margin-right: 0;
  color: $danger;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  content: '*';
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $blue;
}

.ant-pagination {
  display: flex;
  align-items: center;
}

.ant-form-item-label {
  padding: 0 0 4px !important;
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.ant-modal-content {
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
}

.ant-menu-submenu-popup {
  top: 64px !important;
  width: 130px !important;
  position: fixed !important;
  li {
    padding: 16px;
    margin: 0 !important;
    min-height: 56px;
    display: flex;
    align-items: center;
    color: $primary;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    &:hover {
      background: $azure;
      color: $main;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: $azure;
    color: #1d1d1d;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 !important;
}

.ant-menu-submenu-title {
  padding: 16px !important;
}

.ant-input[disabled] {
  color: #272122;
  background-color: #eaf0ff;
  border-color: $blue;
  &:hover {
    border-color: $blue;
  }
}

.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom-width: 3px;
  width: 100%;
  left: 0;
}

.order-accepted {
  color: $accepted;
  background: $bgAccepted;
}

.order-processing {
  color: $main;
  background: $bgProcessing;
}

.order-success {
  color: $success;
  background: $bgSuccess;
}

.order-cancel {
  color: $black;
  background: #eaeaea;
}

.ant-select-selection-placeholder {
  overflow: hidden;
  color: #818181;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Noto Sans JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  &.completed {
    .step-counter {
      background-color: #0b5dea;
      border: 2px solid #0b5dea;
    }
    &::before {
      border-bottom: 2px solid #0b5dea;
    }
    .step-name {
      color: #0b5dea;
    }
    svg {
      * {
        stroke: $white;
      }
    }
  }
  &.cancel {
    .step-counter {
      background-color: #db292c;
      border: 2px solid #db292c;
    }
    &::before {
      border-bottom: 2px solid #0b5dea;
    }
    .step-name {
      color: #db292c;
    }
  }
  &::before,
  &::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid $secondary;
    width: 100%;
    top: 29px;
    z-index: 2;
  }
  &::before {
    left: -50%;
  }
  &::after {
    left: 50%;
  }
  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background: $white;
    border: 2px solid $secondary;
    margin-bottom: 16px;
  }
  .step-name {
    color: $secondary;
    text-align: center;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;
  }
  .step-time {
    color: $primary;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;
  }
  &:first-child {
    &::before {
      content: none;
    }
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
}

.stepper-item-template {
  &.completed {
    svg {
      * {
        stroke: $white;
        fill: $white;
      }
    }
  }
}
.ant-btn.button-form[type='button']:hover,
.ant-btn.button-form[type='button']:focus,
.ant-btn.button-form[type='button']:active {
  background: #eaeaea;
  color: $primary;
  outline: none;
  box-shadow: none;
  border: none;
  &::after {
    display: none;
    outline: none;
    box-shadow: none;
  }
}

.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.ant-modal-close {
  width: 24px;
  height: 24px;
  margin: 16px 24px;
}

.first-login {
  color: $primary;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  .note-icon {
    color: $rose;
  }
}

.button-remove {
  display: flex;
  background: $rose;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  color: $white;
  min-height: 48px;
  justify-content: center;
  border-color: $rose;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  &-icon {
    margin-right: 4px;
  }
  &:hover,
  &:focus,
  &:active {
    background: $rose;
    color: $white;
    outline: none;
    box-shadow: none;
    border: none;
  }
  &[disabled] {
    background: rgba(227, 54, 54, 0.5);
    color: $white;
    border-color: unset;
    border: none;
    &:hover,
    &:focus,
    &:active {
      background: rgba(227, 54, 54, 0.5);
      color: $white;
      outline: none;
      box-shadow: none;
      border: none;
      border-color: unset;
    }
  }
}

.column-redirect {
  color: $main;
  text-overflow: ellipsis;
  font-family: 'Noto Sans JP';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
}

.notify-show {
  color: #08566e;
  background: $mint;
}

.notify-hide {
  color: $rose;
  background: rgba(255, 105, 105, 0.4);
}

.image-in-table {
  max-width: 177px;
  width: auto;
  object-fit: cover;
  height: 100px;
  aspect-ratio: 16/9;
}

.image-in-table-acrylic-plate {
  max-width: 100%;
  width: auto;
  object-fit: cover;
  height: 100px;
  aspect-ratio: 4/5;
}

.image-in-table-template {
  border-radius: 8px;
}

.input_form_textarea {
  resize: none;
}

.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon {
  display: none;
}

.ck-editor__editable {
  min-height: 144px;
  padding: 8px 25px !important;
  max-height: 700px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c0baba;
    border: 2px solid #c0baba;
  }

  a {
    color: #1890ff;
    text-decoration: underline;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
}

.ck-dropdown__panel {
  min-height: 100px;
  max-height: 200px;
  overflow-y: scroll;
}

.number-price {
  min-width: 70px;
  width: max-content;
  text-align: right;
}

.ant-message {
  z-index: 99999;
}

.ant-modal-mask {
  z-index: 99999;
}

.ant-modal-wrap {
  z-index: 99999;
}

.modal-preview-notify .ant-modal-content {
  position: relative;
  border-radius: 60px;
  overflow: hidden;
  .layer-mobile {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 60px;
  }
  .inner-preview {
    max-height: 600px;
    overflow: auto;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    margin: 40px 30px 30px;
    padding: 20px 10px;
    width: calc(100% - 60px);
    height: 100%;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      display: none;
    }
    .detail-content-divider {
      position: absolute;
      top: 85px;
      width: calc(100% - 20px);
    }
    .detail-content-description {
      margin-top: 41px;
    }
  }
  .ant-modal-close {
    position: absolute;
    top: 45px;
    right: 40px;
    margin: 0;
  }
}

.ant-select-clear {
  opacity: 1 !important;
}

.select-type-part {
  width: 100% !important;
  min-height: 40px !important;
  height: 48px;
  border-radius: 8px;
  color: #272122;
  font-size: 16px;
  .ant-select-disabled.ant-select:not(.ant-select-customize-input).ant-select-selector {
    background: #eaf0ff !important;
    border-color: #5277f0 !important;
    color: #272122 !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: red;
  }
  .ant-select-selector {
    height: 100% !important;
    padding: 6px 16px !important;
  }
}

.icon-action {
  cursor: pointer;
}


.ck .ck-button__label{
  font-size: 14px !important;
}
