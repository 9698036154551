// line-clamp
.line-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-6 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

button {
    border: none;
    outline: none;
}

.scroll-custom {
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(216, 214, 214, 0.993);
    }
    &::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
    }
}

.scroll-punchie {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(216, 214, 214, 0.993);
    }
    &::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
    }
}