.navBarEditor {
  width: 100%;
  height: 96px;
  gap: 5px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding: 0 40px;

  .navBarContent {
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    overflow-x: auto;

    .navBarContentItem {
      position: relative;
      text-align: center;
      border-radius: 8px;
      flex: 1;
      border: 1px dashed #5277f0;
      padding: 20px 5px;
      cursor: pointer;

      .icon {
        display: flex;
        justify-content: center;
        svg {
          width: 30px;
          height: 30px;
        }
      }

      .name {
        color: #818181;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
