.ruler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 28px;
  margin: 0 auto 4px;
  border-bottom: 1px solid #71717a;
  flex-direction: row;
  
  .rulerStep {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
  
    .rulerValue {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-50%);
      font-size: 11px;
      color: #71717a;
    }
  
    &::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 6px;
      background-color: #71717a;
    }
  }
  
  .rulerStepStartAndEnd {
    &::before {
      height: 12px;
    }
  }
}

