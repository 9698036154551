.fontPicker {
  width: 100%;
  margin: 0;
  box-shadow: unset;
  min-height: 200px;
}

.dropdownButton {
  box-shadow: unset;
  background-color: #1890ff;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
}

.dropdownFontFamily {
  box-shadow: unset;
  color: white;
  margin: 0;
  padding: 0;
}

.fontList {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #eaeaea;
  transition: 0.3s;
  height: 141px;
  box-shadow: unset;
  background-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 80px;
}

.fontListItem {
  height: 35px;
  list-style: none;
  background-color: transparent;
  box-shadow: unset;
  display: flex;
  align-items: center;
}

.fontButton {
  padding: 0 20px;
  &__choosed {
    color: #1890ff;
  }
}
