.pagination-main {
  .ant-pagination-item-link,
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
  }
  .ant-pagination-next {
    .ant-pagination-item-link {
      justify-content: end;
    }
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  a {
    font-weight: 700;
    color: #272122;
  }
  .ant-pagination-item-active {
    background: #1c48e7;
    border-radius: 50%;
    border: none;
    a {
      color: #f4f5f7;
    }
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1c48e7;
}

.ant-radio-inner::after {
  background-color: #1c48e7;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1c48e7;
}

.ant-radio-checked::after {
  border-color: #1c48e7;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-thead > tr > th {
  background: #f4f4f4;
  padding: 12px;
  font-size: 16px;
  font-weight: 700;
  color: #272122;
}

.ant-table-wrapper tr:nth-child(2n) td {
  background: #f4f4f4 !important;
}

.ant-table-wrapper tr td {
  font-weight: 400;
  font-size: 16px;
  color: #2e2e2e;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset;
}

.antd-select-custom.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 9px 12px;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
}

.antd-select-custom.ant-select-multiple
  .ant-select-selection-overflow-item
  + .ant-select-selection-overflow-item
  .ant-select-selection-search {
  margin-inline-start: 0;
}

.antd-select-custom.ant-select-multiple .ant-select-selection-placeholder {
  left: 12px;
  right: 12px;
}

.antd-select-custom .ant-select-selection-item {
  background: #c6d2ff;
  border-radius: 50px;
  color: #2e2e2e;
  line-height: 20px;
}

.antd-select-custom.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  color: #fff;
  background: #1c48e7;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  line-height: 12px;
}

.antd-select-custom.ant-select-multiple .ant-select-selection-item-remove svg {
  width: 8px;
}

.ant-form .labe-form {
  margin-bottom: 5px;
  color: #2e2e2e;
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  span {
    color: #ca3f4b;
  }
}
.input-form {
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  padding: 12px 16px;
  color: #272122;
  font-size: 16px;
  min-height: 48px;
}

.button-antd-custom {
  display: flex;
  background: #1c48e7;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-height: 40px;
  font-weight: 700;
  font-size: 16px;
  img {
    margin-right: 4px;
  }
}

.button-form {
  background: #eaeaea;
  border-radius: 8px;
  color: #2e2e2e;
  width: 50%;
  padding: 10px;
  height: auto;
  font-weight: 700;
  font-size: 16px;
  border: none;
  min-height: 48px;
  border: 1px solid #000;
  &:hover {
    background: #eaeaea;
    color: #2e2e2e;
    border: 1px solid #000 !important;
  }
}

.button-form-Submit {
  background: #1c48e7;
  color: #ffffff;
  border: 1px solid #1c48e7;
}

.button-form-review.ant-btn,
.button-form-review.ant-btn:hover,
.button-form-review.ant-btn:focus,
.button-form-review.ant-btn:active {
  border: 1px solid rgba(255, 137, 0, 0.34) !important;
}

.button-form-Submit.ant-btn[disabled],
.button-form-Submit.ant-btn[disabled]:hover,
.button-form-Submit.ant-btn[disabled]:focus,
.button-form-Submit.ant-btn[disabled]:active {
  background: #a3bdfc;
  border-color: #a3bdfc !important;
  color: #ffffff;
}

.upload-file-form {
  display: flex;
  flex-direction: column-reverse;
  .ant-upload {
    width: 100%;
    .ant-btn {
      width: 100%;
      min-height: 48px;
      background: #ffffff;
      border: 1px dashed #c0c0c0;
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 16px;
    }
  }
  .ant-upload-list-text-container {
    margin-bottom: 8px;
  }
  .ant-upload-list-item {
    height: auto;
    margin-top: 0px;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: #1c48e7;
  }
  .ant-upload-list-item-info {
    background: #1c48e7;
    border-radius: 8px;
    padding: 12px 10px;
    .ant-upload-list-item-card-actions-btn {
      opacity: 1;
    }
    .ant-btn-icon-only.ant-btn-sm > * {
      font-size: 18px;
    }
    .ant-upload-text-icon {
      display: none;
    }
    .ant-upload-list-item-name {
      color: #ffffff;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .ant-upload-list-text-container {
    .ant-tooltip {
      display: none;
    }
  }
}

.ant-input-number {
  border: 1px solid #ced4da;
  .ant-input-number-input {
    padding: 0px;
  }
}
.ant-input-number-handler-wrap {
  border-radius: 0 8px 8px 0;
}

.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-number:hover {
  border-color: #2263cb;
}

.ant-input-number-disabled {
  background: #eaeaea;
  border-color: #c0c0c0;
}

.ant-input-number-disabled:focus,
.ant-input-number-disabled-focused,
.ant-input-number-disabled:hover {
  border-color: #c0c0c0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  padding: 4px 16px;
  height: auto;
  color: #2e2e2e;
}

.ant-form-item {
  margin: 0px;
}

.ant-btn-primary {
  background-color: #1c48e7;
}
.ant-modal-footer {
  border: none;
}
.button-antd-custom.ant-btn:hover,
.button-add.ant-btn:hover,
.button-antd-custom.ant-btn:focus,
.button-add.ant-btn:focus,
.button-antd-custom.ant-btn:active,
.button-add.ant-btn:active,
.button-form-Submit.ant-btn:hover,
.button-form-Submit.ant-btn:focus,
.button-form-Submit.ant-btn:active,
.login__form__button.ant-btn:hover,
.login__form__button.ant-btn:focus,
.login__form__button.ant-btn:active {
  background-color: #0e28a6 !important;
  border-color: #0e28a6 !important;
  color: #ffffff !important;
}

.delete-Modal {
  .ant-modal-content {
    filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.11));
    border-radius: 8px;
  }
}

.ant-picker {
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 48px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  display: flex;
  align-items: center;
  color: #818181;
  white-space: nowrap;
  font-family: Noto Sans JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.company-page .ant-table-wrapper tr td {
  padding: 12px 16px;
}

.company-page .ant-table-wrapper tr th {
  padding: 12px 16px;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.ar-switch.ant-switch {
  background: #c0c0c0;
  min-width: 46px;
  height: 26px;
}

.ar-switch.ant-switch .ant-switch-handle {
  top: 4px;
  left: 4px;
}

.ar-switch.ant-switch.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 4px);
}

.ar-switch.ant-switch.ant-switch-checked {
  background: #27ae60;
}

.ant-picker-clear {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-picker-suffix {
  cursor: pointer;
  pointer-events: all;
}

.anticon.anticon-close-circle svg {
  vertical-align: unset;
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
}

.ant-select-dropdown {
  z-index: 999;
}

.main-modal {
  .ant-modal-content {
    filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.11));
    border-radius: 8px;
  }
}

.input-price .ant-input-number::before {
  content: '円';
  position: absolute;
  top: 10px;
  right: 24px;
}

.input-price .ant-input-number-input {
  width: 95%;
}

.ant-input-password-icon.anticon {
  color: #272122;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #eaeaea;
}

.ant-input-affix-wrapper-disabled {
  background: #eaeaea;
}

.ant-tabs .ant-tabs-tab {
  width: 50%;
  justify-content: center;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: 500;
  color: #818181;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.license-time-line.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 0px;
}

.license-time-line.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border: none;
}

.license-time-line.react-calendar-timeline .rct-sidebar {
  height: auto !important;
}

.license-time-line .custom-group {
  display: flex;
  flex-direction: column;
}

.license-time-line.react-calendar-timeline .rct-header-root div:first-child {
  height: 16px !important;
  top: 24px;
}

.license-time-line.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border: 0px;
}

.license-time-line.react-calendar-timeline .rct-sidebar {
  border: 0px;
}

.license-time-line.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.license-time-line.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 0px;
}

.license-time-line.react-calendar-timeline .rct-calendar-header div:nth-child(2) .rct-dateHeader {
  font-size: 9px;
}

.license-time-line.react-calendar-timeline .rct-dateHeader {
  border-bottom: 0px;
  background: #1c48e7;
  height: 8px;
  display: block;
}

.license-time-line.react-calendar-timeline .rct-calendar-header {
  border: none;
}

.license-time-line .rct-items .rct-item {
  font-size: 14px !important;
  font-weight: 400;
  background: #0096e8 !important;
  height: 17px !important;
  border: 0px !important;
}

.license-time-line.react-calendar-timeline .rct-item .rct-item-content {
  height: 25px;
  line-height: 25px;
  color: #272122;
  position: absolute;
  top: 20px;
  padding: 0px;
  left: calc(50% - 38px);
  overflow: visible;
  white-space: nowrap;
  cursor: default;
}

.license-time-line.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.license-time-line.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background-color: #ffffff;
}

.license-time-line.react-calendar-timeline .rct-header-root {
  background: #ffffff;
  border-bottom: 0px;
}

.license-time-line.react-calendar-timeline .rct-dateHeader span {
  position: absolute;
  color: #272122;
  font-weight: 700;
  font-size: 16px;
  top: -30px;
  left: -30px;
}

.license-time-line.react-calendar-timeline .rct-dateHeader.start-time-line {
  span {
    left: 0px;
  }
}

.license-time-line.react-calendar-timeline .rct-dateHeader.end-time-line {
  span {
    left: -68px;
  }
}

.license-time-line.react-calendar-timeline .rct-dateHeader::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 22.067px;
  background: #1c48e7;
  border-radius: 10px;
  top: -6px;
  left: -4px;
}

.license-time-line .now-date-icon {
  width: 15px !important;
  height: 22px !important;
  position: absolute;
  left: -7.5px;
}

.license-time-line.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.license-time-line.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: #fff;
}
