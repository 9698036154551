a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: unset;
  }
  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

button {
  border: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: initial;
  margin-bottom: 0;
  font-style: initial;
}

p {
  font-size: 14px;
  margin-bottom: 0;
  font-style: initial;
}

body {
  height: auto;
}

#font-picker {
  width: 100%;
  margin: 0 -20px;
  box-shadow: unset;
}
#font-picker .dropdown-button {
  background-color: transparent;
  box-shadow: unset;
  background-color: #1890ff;
  padding: 0 20px;
}
#font-picker .dropdown-button .dropdown-font-family {
  box-shadow: unset;
  color: white;
}
#font-picker .dropdown-button .dropdown-icon {
  display: none;
}
#font-picker .font-list {
  max-height: 100px !important;
  min-height: 100px !important;
  box-shadow: unset;
  background-color: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#font-picker .font-list::-webkit-scrollbar {
  display: none;
}
#font-picker .font-list .font-list-item {
  background-color: transparent;
  box-shadow: unset;
}
#font-picker .font-list .font-list-item button {
  padding: 0 20px;
}
#font-picker .font-list .font-list-item .active-font {
  background-color: transparent;
  color: #1890ff;
}

.my-react-add-text .react-colorful {
  height: 100%;
  width: 100%;
}
.my-react-add-text .react-colorful__saturation {
  border-radius: 4px 4px 0 0;
}
.my-react-add-text .react-colorful__hue {
  height: 30px;
  border-radius: 0 0 4px 4px;
}

/* ==== en ==== */

@font-face {
  font-family: Lato;
  src: url('./font-en/Lato-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Merriweather;
  src: url('./font-en/Merriweather-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Montserrat;
  src: url('./font-en/Montserrat-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Noto Sans;
  src: url('./font-en/NotoSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: OpenSans;
  src: url('./font-en/OpenSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Poppins;
  src: url('./font-en/Poppins-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Radio Canada Big;
  src: url('./font-en/RadioCanadaBig-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Raleway;
  src: url('./font-en/Raleway-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Roboto;
  src: url('./font-en/Roboto-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Rubik;
  src: url('./font-en/Rubik-Regular.ttf') format('opentype');
}

/* ==== jp ==== */

@font-face {
  font-family: Noto Sans Japanese;
  src: url('./font-jp/NotoSansJP-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Noto Serif Japanese;
  src: url('./font-jp/NotoSerifJP-Regular.ttf') format('opentype');
}

@font-face {
  font-family: M PLUS 1p;
  src: url('./font-jp/MPLUS1p-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Zen Kaku Gothic New;
  src: url('./font-jp/ZenKakuGothicNew-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Zen Maru Gothic;
  src: url('./font-jp/ZenMaruGothic-Regular.ttf') format('opentype');
}

@font-face {
  font-family: BIZ UDPGothic;
  src: url('./font-jp/BIZUDPGothic-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Dela Gothic One;
  src: url('./font-jp/DelaGothicOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: M PLUS 1;
  src: url('./font-jp/MPLUS1-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Shippori Mincho B1;
  src: url('./font-jp/ShipporiMinchoB1-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Kosugi;
  src: url('./font-jp/Kosugi-Regular.ttf') format('opentype');
}

@font-face {
  font-family: M PLUS 2;
  src: url('./font-jp/MPLUS2-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Zen Kaku Gothic Antique;
  src: url('./font-jp/ZenKakuGothicAntique-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Murecho;
  src: url('./font-jp/Murecho-Regular.ttf') format('opentype');
}

@font-face {
  font-family: BIZ UDGothic;
  src: url('./font-jp/BIZUDGothic-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Yusei Magic;
  src: url('./font-jp/YuseiMagic-Regular.ttf') format('opentype');
}

@font-face {
  font-family: IBM Plex Sans JP;
  src: url('./font-jp/IBMPlexSansJP-Regular.ttf') format('opentype');
}

@font-face {
  font-family: DotGothic16;
  src: url('./font-jp/DotGothic16-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Zen Kurenaido;
  src: url('./font-jp/ZenKurenaido-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Monomaniac One;
  src: url('./font-jp/MonomaniacOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Mochiy Pop One;
  src: url('./font-jp/MochiyPopOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: RocknRoll One;
  src: url('./font-jp/RocknRollOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Stick;
  src: url('./font-jp/Stick-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Rampart One;
  src: url('./font-jp/RampartOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Hina Mincho;
  src: url('./font-jp/HinaMincho-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Mochiy Pop P One;
  src: url('./font-jp/MochiyPopPOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Reggae One;
  src: url('./font-jp/ReggaeOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Hachi Maru Pop;
  src: url('./font-jp/HachiMaruPop-Regular.ttf') format('opentype');
}

@font-face {
  font-family: M PLUS 1 Code;
  src: url('./font-jp/MPLUS1Code-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Yomogi;
  src: url('./font-jp/Yomogi-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Aoboshi One;
  src: url('./font-jp/AoboshiOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Chokokutai;
  src: url('./font-jp/Chokokutai-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Cherry Bomb One;
  src: url('./font-jp/CherryBombOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Slackside One;
  src: url('./font-jp/SlacksideOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Tsukimi Rounded;
  src: url('./font-jp/TsukimiRounded-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Darumadrop One;
  src: url('./font-jp/DarumadropOne-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Palette Mosaic;
  src: url('./font-jp/PaletteMosaic-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Shizuru;
  src: url('./font-jp/Shizuru-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Yuji Hentaigana Akari;
  src: url('./font-jp/YujiHentaiganaAkari-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Rock 3D;
  src: url('./font-jp/Rock3D-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Yuji Hentaigana Akebono;
  src: url('./font-jp/YujiHentaiganaAkebono-Regular.ttf') format('opentype');
}
