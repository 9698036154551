.wrapRenderStep {
  padding: 0 20px;
  position: relative;

  .wrapImage {
    border: 1px solid black;
    overflow: hidden;
    background-size: cover;
    background-position: center;
  }
}
